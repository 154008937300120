import { useEffect, useState, useRef } from 'react';

const isElementOffScreen = (ref) => {
  const {top, height} = ref.getBoundingClientRect();
  return top < height * -1;
};

const useScrollShow = ({afterElementRef}) => {
  const [scrollContentShow, setScrollContentShow] = useState(false);
  const prevYref = useRef(0);



  useEffect(() => {
    const handleScroll = () => {
      const offScreen = afterElementRef ? isElementOffScreen(afterElementRef.current) : true;

      const prevY = prevYref.current;
      const currentY = window.scrollY;

      const scrollingDown = currentY > prevY;

      // don't set it if it's the same
      const different = scrollingDown !== scrollContentShow;
      if(different) {
        setScrollContentShow(scrollingDown && offScreen);
      }
     
      prevYref.current = currentY;
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [scrollContentShow, setScrollContentShow, prevYref]);

  return scrollContentShow;
};

export default useScrollShow;
