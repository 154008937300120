import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import propTypes from 'prop-types';
import React, { useState, useRef } from 'react';

import Button from '../components/Button';
import { useBag } from '../context/BagContext';
import { useBagController } from '../context/BagDisplayContext';
import Layout from '../layouts/Layout';
import { currencyMask } from '../services/maskService';
import { productFactory } from '../services/productFactory';
import useScrollShow from '../util/useScrollShow';

import styles from './product.module.css';

export const ProductPageTemplate = ({
  image,
  name,
  price,
  description,
  addToBag,
  seller,
  tags,
  category,
  metaDescription,
  metaKeywords,
  url,
  bank,
  html,
}) => {
  const addToBagButtonRef = useRef();
  const showScroll = useScrollShow({ afterElementRef: addToBagButtonRef });
  const aspectRatio = image.childImageSharp.fluid.aspectRatio; // width / height
  const categoryTemplate = category ? (
    <div className="flex flex-wrap text-gray-800 my-8 w-full">
      <span>Category: </span>
      <Link to={`/categories${category.fields.slug}`} className="text-black ml-1 font-semibold">
        {category.name}
      </Link>
    </div>
  ) : (
    <></>
  );

  const tagsTemplate = tags ? (
    <div className="flex flex-wrap text-gray-800 mt-4">
      {tags.map((tag, i) => (
        <div
          className={`rounded-sm bg-gray-100 hover:bg-gray-200 text-gray-600 text-xs font-semibold py-2 pl-2 pr-3 active:translate-y-1 transform leading-none m-1 border border-gray-200`}
          key={i}
        >
          <Link to={`/tags${tag.fields.slug}`}>
            <span className="align-middle">{tag.name}</span>
          </Link>
        </div>
      ))}
    </div>
  ) : (
    <></>
  );

  const brandTemplate = (
    <div className="flex flex-row text-gray-800 mt-4">
      <span>More products from </span>
      <Link to={`/sellers${seller.fields.slug}`} className="text-black ml-1 font-semibold">
        {seller.name}
      </Link>
      .
    </div>
  );

  return (
    <Layout title={name} description={description} metaDescription={metaDescription} metaKeywords={metaKeywords}>
      <div className="w-full flex flex-col items-center md:flex-row md:items-start md:justify-center">
        {image.childImageSharp ? (
          <Img
            className={`w-full md:w-2/6 bg-white rounded-md border border-gray-200`}
            fluid={image.childImageSharp.fluid}
            alt={name}
          />
        ) : (
          <img src={image} alt={name} className={`w-full md:w-100 ${styles.customBorder}`} />
        )}

        <div className="flex flex-grow flex-col items-center w-full md:w-4/6 md:mt-0 md:ml-12 md:items-start">
          <div className="w-full flex flex-row items-baseline justify-between my-6 lg:mt-0 lg:mb-2">
            <h1 className="text-black text-2xl sm:text-4xl font-extrabold lg:text-5xl leading-tight">
              {name}
            </h1>
            <span
              className={`flex-shrink-0 text-black-700 font-bold text-xl sm:text-4xl ml-6 ${styles.highlight}`}
            >
              {currencyMask(price)}
            </span>
          </div>
          <div className="w-full flex flex-col items-start">
            <div className="mt-2">
              {tagsTemplate}
              {categoryTemplate}
              {brandTemplate}
            </div>
          </div>
          <div className="w-full flex flex-row mt-8">
            <Button
              className="mr-2"
              forwardRef={addToBagButtonRef}
              text="Save"
              onClick={addToBag}
            />
            <Button tag="a" href={url} target="_blank" className="ml-2" text="Shop Now" invert />
          </div>
          <div className="w-full flex flex-row text-gray-800 mt-2 text-xs">
            <span>Need finance? Visit </span>
            <div className="text-black ml-1 font-semibold">{bank}</div>
          </div>
          <div className="w-full sm:w-4/5 md:w-4/5 my-8">
            <p className="text-black text-xl font-normal">{description}</p>
          </div>
          <div
            className="w-full sm:w-4/5 md:w-4/5 my-8"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>

      <div className={`w-full flex fixed rounded-t-lg bg-gray-100 border border-gray-200 ${!showScroll ? styles.hidden : ''} ${styles.ctaSection}`}>
        <div className="hidden ml-3 md:ml-24">
          <Img
            className={`${styles.ctaImage} bg-white`}
            style={{ '--aspectRatio': aspectRatio }}
            fluid={image.childImageSharp.fluid}
            alt={name}
          />
        </div>
        <div className="hidden ml-3 md:ml-24 mx-3 text-lg font-semibold w-full h-full text-black sm:flex items-center">
          Enjoy this product, and many more, over at {seller.name}
        </div>
        <div className="mx-3 md:mr-24 md:w-1/3 w-full h-full flex items-center">
          <Button invert tag="a" href={url} target="_blank" text="Shop Now" className="w-full" />
        </div>
      </div>
    </Layout>
  );
};

const ProductPage = ({ data: { markdownRemark } }) => {
  const { showBag } = useBagController();
  const [quantity, setQuantity] = useState(1);
  const { addProduct } = useBag();

  const product = productFactory(markdownRemark);

  const increaseQuantity = () => {
    setQuantity((quantity) => quantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((quantity) => quantity - 1);
    }
  };

  const addToBag = () => {
    addProduct({ ...product, quantity });
    showBag();
  };

  return (
    <ProductPageTemplate
      image={product.image}
      name={product.name}
      price={product.price}
      description={product.description}
      seller={product.seller}
      tags={product.tags}
      metaDescription={product.metaDescription}
      metaKeywords={product.metaKeywords}
      category={product.category}
      html={product.html}
      url={product.url}
      bank={product.bank}
      quantity={quantity}
      increaseQuantity={increaseQuantity}
      decreaseQuantity={decreaseQuantity}
      addToBag={addToBag}
    />
  );
};

export const query = graphql`
  query ProductById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 400, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        metaDescription
        metaKeywords
        description
        name
        price
        url
        bank
        seller {
          id
          name
          image
          fields {
            slug
          }
        }
        tags {
          id
          name
          fields {
            slug
          }
        }
        category {
          id
          name
          metaDescription
          metaKeywords
          fields {
            slug
          }
        }
      }
    }
  }
`;

ProductPageTemplate.propTypes = {
  image: propTypes.shape().isRequired,
  name: propTypes.string.isRequired,
  price: propTypes.number.isRequired,
  quantity: propTypes.number.isRequired,
  description: propTypes.string.isRequired,
  metaDescription: propTypes.string.isRequired,
  metaKeywords: propTypes.string.isRequired,
  increaseQuantity: propTypes.func.isRequired,
  decreaseQuantity: propTypes.func.isRequired,
  addToBag: propTypes.func.isRequired,
  seller: propTypes.shape().isRequired,
  tags: propTypes.array,
  category: propTypes.shape(),
};

ProductPage.propTypes = {
  data: propTypes.shape({
    markdownRemark: propTypes.shape.isRequired,
  }).isRequired,
};

export default ProductPage;
